import React, { useState, useEffect, SyntheticEvent } from 'react';

import { IPageFields } from '@/types/contentful';

import { usePageDataContext, UrlByEnv } from '../../../context/PageData';
import ConsentService from '../../../services/consent';

import { trackWithFingerprint } from '../../../utils/trackWithFingerprint';
import { trackRumEvent } from '../../../utils/datadogRum';
import { getAnonymousId } from '../../../utils/getAnonymousId';
import errors from '../../../utils/errors';
import { phoneNumberRegex, emailRegex } from '../../../utils/regex';
import { getRedirectByEnv, appendParams } from '../../../utils/buildWebRegUrl';
import analytics from '../../../utils/analytics';
import { usePublicRuntimeConfig } from '../../../hooks/usePublicRuntimeConfig';

import { CtaWidgetMap } from './CtaWidgetMap';

import {
  SharedProps,
  DEFAULT_LABEL,
  ctaWidgetEnum,
  RegistrationEntrypoint,
  DEFAULT_PLACEHOLDER
} from '../shared';
import { TextInputType } from '../../FormFields/shared';
import { InputProps } from '../../Input';
import { setCookie } from 'cookies-next';

import { Wrapper, Container, FlexForm } from '../styles';
import { getCookie } from '~/utils/cookies';
import { initialize } from '~/lib/onboarding/initialize';

interface WebRegWidgetProps extends SharedProps, RegistrationEntrypoint {
  module?: string;
  height?: string | number;
  width?: string | number;
  bypassPhoneNumberEntry?: boolean;
  isReturning?: boolean;
  additionalSegmentData?: any;
}

export function Widgets({
  id,
  className,
  module,
  forwardedCtaParams,
  designType,
  label = DEFAULT_LABEL,
  placeholder = DEFAULT_PLACEHOLDER,
  errorPosition = 'bottom',
  showErrorBanner = true,
  sendConsentEvent = false,
  height = '100%',
  width = 'fit-content',
  bypassPhoneNumberEntry = false,
  url,
  openInNewTab,
  autocompleteAttribute = 'tel',
  segmentEventName,
  additionalSegmentData,
  successMessage,
  ...widgetProps
}: WebRegWidgetProps) {
  const isEmailWidget =
    [ctaWidgetEnum.EMAIL].includes(designType as ctaWidgetEnum) ||
    autocompleteAttribute?.includes('email');

  const isRoleWidget = designType === ctaWidgetEnum.ROLE_SELECT_WEBREG;

  const [value, setValue] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>();

  const { onboardingUrl } = usePublicRuntimeConfig();
  const { page, webregIncident = false } = usePageDataContext();
  const { advocateUserData } = usePageDataContext();
  const { webRegUrl, stagingWebRegUrl, previewWebRegUrl, forwardedUrlParams } =
    (page as IPageFields) ?? {};

  useEffect(() => {
    const setFromEvent = () => setErrorMessage('');
    window.addEventListener('mousedown', setFromEvent);

    return () => {
      window.removeEventListener('mousedown', setFromEvent);
    };
  }, []);

  const validate = () => {
    let errMsg;

    switch (designType) {
      case ctaWidgetEnum.EMAIL:
        if (!value.match(emailRegex)) {
          return errors.INVALID_EMAIL;
        }

        return errMsg;

      case ctaWidgetEnum.GET_STARTED_V1:
      case ctaWidgetEnum.DESKTOP_PHONE_NUMBER_V1:
      case ctaWidgetEnum.INBLOCK_PHONE_NUMBER_V1:
      case ctaWidgetEnum.MOBILE_PHONE_NUMBER:
      case ctaWidgetEnum.MOBILE_PHONE_NUMBER_DROPSHADOW:
      case ctaWidgetEnum.DESKTOP_PHONE_NUMBER:
      case ctaWidgetEnum.GET_STARTED:
        if (isEmailWidget && !value.match(emailRegex)) {
          return errors.INVALID_EMAIL;
        }

        const unformattedPhoneNumber = value.replace(/\D/g, '');
        if (
          bypassPhoneNumberEntry === false &&
          !isEmailWidget &&
          (unformattedPhoneNumber.length < 10 ||
            !unformattedPhoneNumber.match(phoneNumberRegex))
        ) {
          return errors.INVALID_PHONE;
        }
        return errMsg;

      case ctaWidgetEnum.GET_STARTED_NO_PHONE:
      case ctaWidgetEnum.STANDARD:
      case ctaWidgetEnum.GL4C_AUTH:
      case ctaWidgetEnum.GL4C_INLINE_AUTH:
      case ctaWidgetEnum.ROLE_SELECT_WEBREG:
      default:
        return errMsg;
    }
  };

  const fireAnalytics = async () => {
    const segmentValueKey = isEmailWidget ? 'email' : 'phone';
    const event = {
      page_url: window.location.href,
      widgetType: designType,
      module,
      ...additionalSegmentData,
      [segmentValueKey]: value
    };

    if (bypassPhoneNumberEntry) {
      await trackWithFingerprint(segmentEventName || 'CTA Clicked', event);
    } else {
      let eventName = 'Phone Number Entered';

      if (isEmailWidget) {
        eventName = 'Email Address Entered';
      } else if (isRoleWidget) {
        eventName = 'CTA Clicked';
      }

      await trackWithFingerprint(segmentEventName || eventName, event);
    }

    if (sendConsentEvent) {
      const userId = analytics.anonymousId || false;

      await ConsentService.collectConsent({
        userId,
        type: 'affiliate_SEC_disclaimer',
        pageName: 'Affiliate Page'
      });
    }
  };

  const webregRedirect = async () => {
    const anonymousId = getAnonymousId();

    const ctaUrlField = getRedirectByEnv(url?.fields as UrlByEnv);
    const pageUrlField = getRedirectByEnv({
      production: webRegUrl,
      staging: stagingWebRegUrl,
      development: previewWebRegUrl
    });

    // Check ctf CTA field or ctf page field or use env vars
    const baseUrl = ctaUrlField || pageUrlField;

    const initParams = {
      phoneNumber: !isEmailWidget ? value.replace(/\D/g, '') : undefined,
      emailAddress: isEmailWidget ? value : undefined,
      bypassPhoneNumberEntry,
      forwardedUrlParams: forwardedUrlParams as string[],
      forwardedCtaParams,
      advocateUserData,
      role: isRoleWidget ? value : undefined,
      anonymousId
    };

    await initialize(initParams);

    if (isRoleWidget) {
      if (value === 'parent') {
        trackWithFingerprint('Primary Hero CTA - Parent');
      } else if (value === 'child') {
        trackWithFingerprint('Secondary Hero CTA - Child');
      }
    }

    // Common event tracking
    trackRumEvent('user_to_registration', {
      id: anonymousId,
      anonymousId,
      ...(isEmailWidget
        ? { emailAddress: value }
        : { phoneNumber: value.replace(/\D/g, '') })
    });

    if (baseUrl) {
      const targetUrl = appendParams({
        baseUrl,
        ...initParams
      });

      window.location.assign(targetUrl);
    } else {
      window.location.assign(onboardingUrl);
    }
  };

  const onSuccess = () => {
    switch (designType) {
      case ctaWidgetEnum.GL4C_AUTH:
      case ctaWidgetEnum.GL4C_INLINE_AUTH:
        return;

      case ctaWidgetEnum.EMAIL:
        if (module === 'Lead Capture') {
          trackWithFingerprint('Lead Capture Form Submitted', {
            email: value
          });

          return;
        }

        trackWithFingerprint(`${segmentEventName} - Form Submitted`, {
          email: value
        });
        return;

      case ctaWidgetEnum.STANDARD:
        const envBasedUrl = getRedirectByEnv(url?.fields as UrlByEnv);
        if (envBasedUrl) {
          return openInNewTab
            ? window.open(envBasedUrl, '_blank', 'noopener,noreferrer')
            : window.location.assign(envBasedUrl);
        }

        return webregRedirect();

      case ctaWidgetEnum.ROLE_SELECT_WEBREG:
      case ctaWidgetEnum.GET_STARTED_NO_PHONE:
      case ctaWidgetEnum.MOBILE_PHONE_NUMBER:
      case ctaWidgetEnum.MOBILE_PHONE_NUMBER_DROPSHADOW:
      case ctaWidgetEnum.DESKTOP_PHONE_NUMBER:
      case ctaWidgetEnum.GET_STARTED:
      default:
        if (
          segmentEventName &&
          !getCookie('userCTAInteraction')?.includes(segmentEventName)
        ) {
          const userSegments = getCookie('userCTAInteraction')
            ? getCookie('userCTAInteraction').split(',')
            : [];
          const newCookieValue =
            userSegments.length > 0
              ? userSegments.concat(segmentEventName).join(',')
              : segmentEventName;
          setCookie('userCTAInteraction', newCookieValue, {
            encode: (value: string) => value
          });
        }
        return !segmentEventName && webregRedirect();
    }
  };

  const handleOnSubmit = async (e: SyntheticEvent) => {
    e.preventDefault();
    const err = validate();

    if (err) {
      setErrorMessage(err);
      trackWithFingerprint('Failed to capture CTA', {
        page_url: window.location.href,
        widgetType: designType,
        module
      });
      return;
    }

    await fireAnalytics();

    setErrorMessage(null);
    setSubmitted(true);
    onSuccess();
  };

  const inputProps: InputProps = {
    id,
    type: isEmailWidget ? TextInputType.EMAIL : TextInputType.PHONE,
    value,
    placeholder,
    handleOnChange: (val: string) => setValue(val),
    inputMode: isEmailWidget ? 'text' : 'numeric',
    disabled: submitted,
    'aria-invalid': !!errorMessage,
    'aria-describedby': 'cta-error-message'
  };

  return webregIncident && module !== 'Lead Capture' ? null : (
    <Wrapper height={height} width={width}>
      <Container
        className={className}
        data-e2e="cta-container"
        data-testid="cta"
      >
        <FlexForm onSubmit={handleOnSubmit} width={width} noValidate>
          <CtaWidgetMap
            {...widgetProps}
            designType={designType as ctaWidgetEnum}
            label={label}
            errorMessage={errorMessage}
            errorPosition={errorPosition}
            showErrorBanner={showErrorBanner}
            submitted={submitted}
            inputProps={inputProps}
            segmentEventName={segmentEventName}
            successMessage={successMessage}
          />
        </FlexForm>
      </Container>
    </Wrapper>
  );
}

import React from 'react';

import { AffiliateCta, AffiliateCtaProps } from './AffiliateCta';
import { Widgets } from './Widgets';

import { ctaWidgetEnum, PartialCtaProps } from './shared';
import { usePageDataContext } from 'src/context/PageData';

interface CtaWidgetProps extends PartialCtaProps {
  height?: string | number;
  width?: string | number;
  isScrolled?: boolean;
  onStartClick?: () => void;
  module?: string;
  isMobile?: boolean;
  responsive?: boolean;
  additionalSegmentData?: any;
}

export default function CtaWidget({
  sysId,
  designType,
  ...props
}: CtaWidgetProps) {
  const { isReturning } = usePageDataContext();

  switch (sysId) {
    case 'affiliateCta':
      return <AffiliateCta {...(props as AffiliateCtaProps)} />;

    case 'legacyCta':
    case 'registrationEntrypoint':
    default:
      return (
        <Widgets
          {...props}
          isReturning={isReturning}
          designType={designType as ctaWidgetEnum}
          bypassPhoneNumberEntry={[
            ctaWidgetEnum.GET_STARTED_NO_PHONE,
            ctaWidgetEnum.STANDARD,
            ctaWidgetEnum.GL4C_AUTH,
            ctaWidgetEnum.GL4C_INLINE_AUTH
          ].includes(designType as ctaWidgetEnum)}
        />
      );
  }
}
